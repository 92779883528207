// import { Search } from "@mui/icons-material";
import { Box, Breadcrumbs, /* Button, */ Divider, Grid2, /* InputAdornment, */ Link, /* TextField, */ Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import AppLoader from "../components/AppLoader";
import ContentWrapper from "../components/ContentWrapper";
// import FilterListTooltip from "../components/FilterListTooltip";
import FilterNav from "../components/FilterNav";
import PageHeading from "../components/PageHeading";
import TableAdvanced from "../components/TableAdvanced";
import { operatorsCells } from "../constants";
import allRoutes from "../constants/routes";
import { useSaveSearchMutation } from "../store/apis/save";
import { useGetCountriesQuery, useGetOperatorsQuery, useLazyExportOperatorsQuery } from "../store/apis/search";
// import { selectSaveToken } from "../store/slices/auth";
import { selectOperatorsSlice, /* setFilter, */ setOrder, setPage, setRowsPerPage, setSavedValues } from "../store/slices/operators";
import { selectUiState, setDense, setFontSize } from "../store/slices/ui";
import { OperatorType } from "../types";
import { mapValuesToSaveData } from "../utils";
import ExportCsv from "../components/ExportCsv";
import { isEqual } from "lodash";
import SaveSearch from "../components/SaveSearch";
import { selectExport } from "../store/slices/auth";
const OperatorsSearch: React.FC = () => {
  const dispatch = useDispatch();
  const { dense, fontSize, exportFields } = useSelector(selectUiState);
  const { sortBy, desc, rowsPerPage, page, filter, values, lastSaved } = useSelector(selectOperatorsSlice);
  const exportEnabled = useSelector(selectExport);
  // const token = useSelector(selectSaveToken);
  const [saveSearch, { isLoading: isSaving }] = useSaveSearchMutation();
  const { data: countries = [] } = useGetCountriesQuery({
    ituRegions: [],
    regions: values.regions,
  });

  const [trigger, { data: download, isLoading: isExporting }] = useLazyExportOperatorsQuery();
  const {
    data = { results: [], total: 0, count: 0 },
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetOperatorsQuery({
    rowsPerPage,
    page,
    values,
    sortBy,
    sortDirection: desc ? "desc" : "asc",
    format: "json",
  });

  const { results = [] } = data;
  const loading = isLoading || isFetching || isUninitialized;
  const total = data?.count || 0;
  const pages = Math.ceil(total / rowsPerPage);
  const trimmedResults = results.map((item: OperatorType) => {
    const foundCountryObject = countries.find(countryItem => countryItem.id === item.country_id);
    return { ...item, country_id: foundCountryObject?.title || "-" };
  });
  const filteredResults = trimmedResults.filter((item: OperatorType) => {
    if (filter.length > 0) {
      return item.title.toLowerCase().indexOf(filter.toLowerCase()) > -1;
    } else return true;
  });

  const handleSave = () => {
    dispatch(setSavedValues(values));
    saveSearch({ sectionId: 4, data: mapValuesToSaveData(4, values) });
  };

  const renderButtons = () => (
    <Box paddingY={4}>
      <Grid2 container spacing={4} alignItems="center">
        <Grid2>
          <SaveSearch disabled={isEqual(values, lastSaved)} loading={isSaving} onClick={handleSave} />
        </Grid2>
        <Grid2>
          <ExportCsv
            disabled={exportEnabled ? false : total > 2000}
            source={download}
            loading={isExporting}
            onClick={() => trigger({ values: values, fields: exportFields })}
          />
        </Grid2>
      </Grid2>
    </Box>
  );

  return (
    <>
      <Helmet title="Operators - GSA GAMBoD" />
      <FilterNav />
      <PageHeading
        title={allRoutes.operators.label}
        icon={allRoutes.operators.icon}
        color={allRoutes.operators.color}
        section="operators"
        inset
      >
        <Breadcrumbs aria-label="Breadcrumb">
          <Link href={allRoutes.dashboard.path}>Dashboard</Link>
          <Link href={allRoutes.operators.path}>Operators</Link>
          <Typography>Search</Typography>
        </Breadcrumbs>
      </PageHeading>
      <ContentWrapper inset>
        <Grid2 container justifyContent="space-between" alignItems="center">
          <Grid2 size="grow">
            <Box paddingY={10}>
              <Grid2 container spacing={8} alignItems="flex-end">
                <Grid2 sx={{ display: { xs: "none", md: "block" } }}>
                  <Typography variant="h4">Your Results</Typography>
                </Grid2>
                <Grid2>
                  <Typography variant="subtitle2" gutterBottom>
                    Returned results
                  </Typography>
                  <Typography variant="h4">
                    <span data-testid="operatorsTotal" data-testvalue={total}>
                      {total.toLocaleString()}
                    </span>{" "}
                    Operators
                  </Typography>
                </Grid2>
              </Grid2>
            </Box>
          </Grid2>
          <Grid2 sx={{ display: { xs: "none", md: "block" } }}>{renderButtons()}</Grid2>
        </Grid2>
      </ContentWrapper>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <ContentWrapper inset>
          <Divider />
          {renderButtons()}
        </ContentWrapper>
      </Box>
      <Divider />
      <AppLoader loading={loading} />
      <ContentWrapper body inset>
        {/* <Box paddingY={4}>
          <Grid2 container alignItems="center" wrap="nowrap">
            <Grid2 >
              <FilterListTooltip title="Search operators by name" />
            </Grid2>
            <Grid2 >
              <Grid2 container alignItems="center" spacing={2} wrap="nowrap">
                <Grid2 >
                  Filter the results by name: &nbsp;
                </Grid2>
                <Grid2 >
                  <TextField
                    id="input-with-icon-textfield"
                    variant="outlined"
                    size="small"
                    value={filter}
                    placeholder="Search results"
                    onChange={event => {
                      dispatch(setFilter(event.target.value));
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid2>
                <Grid2 >
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      dispatch(setFilter(""));
                    }}
                  >
                    Clear
                  </Button>
                </Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        </Box>
        <Divider /> */}
        <TableAdvanced
          title={`GAMBoD Operators Search - ${total.toLocaleString()} results, page ${page}`}
          dense={dense}
          fontSize={fontSize}
          rows={filteredResults}
          cellsMap={operatorsCells(sortBy, desc)}
          page={page}
          pages={pages}
          rowsPerPage={rowsPerPage}
          loading={loading}
          setRowsPerPage={value => {
            dispatch(setRowsPerPage(value));
          }}
          setPage={value => {
            dispatch(setPage(value));
          }}
          setFontSize={value => {
            console.log("setFontSize", value);
            dispatch(setFontSize(value));
          }}
          setDense={value => {
            dispatch(setDense(value));
          }}
          onOrder={value => {
            dispatch(setOrder(value));
          }}
        />
      </ContentWrapper>
    </>
  );
};

export default OperatorsSearch;
