import routes from "./routes";
import { TableCell } from "../components/Table";
import { OptionType } from "../types";
import { commercialOptions } from "./commercial";
import {
  DevicesValuesType,
  NetworksValuesType,
  PrivateNetworksValuesType,
  formatSatVendors,
  formatSwitchOffs,
  spreadTitles,
} from "../utils";

import { monthAbbr } from "./date";

export const networksCells = (
  sortBy: string,
  desc: boolean,
  values: NetworksValuesType | PrivateNetworksValuesType,
  isPrivate: boolean,
): TableCell[] => {
  const array = [];

  array.push({
    id: "operator",
    label: "Customer",
    sortable: true,
    active: sortBy === "operator",
    desc: sortBy === "operator" ? desc : false,
    value: (row: any) => {
      return `${row.operator_title}`;
    },
    link: {
      urlBase: "/operators/operator/",
      urlParam: "operator_id",
    },
    size: "small",
  });

  array.push({
    id: "country",
    label: "Country",
    sortable: true,
    active: sortBy === "country",
    desc: sortBy === "country" ? desc : false,
    value: (row: any) => `${row.country_title}`,
    size: "small",
  });

  array.push({
    id: "regions",
    label: "Regions",
    sortable: false,
    value: (row: any) => row.regions?.map((option: OptionType) => option.title || "").join(", ") || "",
    size: "small",
  });

  if (!isPrivate) {
    if (values.features.length) {
      array.push({
        id: "features",
        label: "Features",
        sortable: false,
        value: (row: any) => row.features?.map((option: OptionType) => option.title || "").join(", "),
        size: "large",
      });
    }

    if (values.frequencyBands.length) {
      array.push({
        id: "frequencies",
        label: "Frequencies",
        sortable: false,
        value: (row: any) => row.frequency_bands?.map((option: OptionType) => option.title || "").join(", "),
        size: "medium",
      });
    }

    array.push({
      id: "technology",
      label: "Technology",
      sortable: true,
      active: sortBy === "technology",
      desc: sortBy === "technology" ? desc : false,
      value: (row: any) => {
        return `${row.technologies.map((option: OptionType) => option.title || "").join(", ")}`;
      },
      size: "medium",
    });

    if ((values as NetworksValuesType)?.subTechnologies?.length) {
      array.push({
        id: "sub_technology",
        label: "Subtech",
        sortable: true,
        active: sortBy === "sub_technology",
        desc: sortBy === "sub_technology" ? desc : false,
        value: (row: any) => {
          return `${row.technologies.map((option: OptionType) => option?.sub_technology?.title || "").join(", ")}`;
        },
        size: "medium",
      });
    }

    array.push({
      id: "satellite_vendors",
      label: "Satellite vendors",
      sortable: true,
      active: sortBy === "satellite_vendors",
      desc: sortBy === "satellite_vendors" ? desc : false,
      value: (row: any) => {
        return formatSatVendors(row.satellite_vendors);
      },
      size: "medium",
    });

    if (values.ue.length) {
      array.push({
        id: "ue_speed",
        label: "UE Category",
        sortable: false,
        value: (row: any) => {
          return `${row.ue_speed_title ? row.ue_speed_title : ""}`;
        },
        size: "medium",
      });
    }

    array.push({
      id: "status",
      label: "Status",
      sortable: true,
      active: sortBy === "status",
      desc: sortBy === "status" ? desc : false,
      value: (row: any) =>
        `${row.status_title}${
          row.month || row.year
            ? ` (${row.month ? monthAbbr[parseInt(row.month) - 1] : ""}${row.year && row.month ? " " : ""}${row.year ? `${row.year}` : ""})`
            : ""
        }`,
      size: "medium",
    });

    array.push({
      id: "usage",
      label: "Usage",
      sortable: true,
      active: sortBy === "usage",
      desc: sortBy === "usage" ? desc : false,
      value: (row: any) => {
        return row.usage?.title || "";
        // can there be more than one?
        // return row.usage.lnegth > 0 ? row.usage.map((option: OptionType) => option.title || "").join(", ") : ""
      },
      size: "medium",
    });

    array.push({
      id: "switch_off_2g",
      label: "2G Switch Off",
      sortable: true,
      active: sortBy === "switch_off_2",
      desc: sortBy === "switch_off_2" ? desc : false,
      value: (row: any) => formatSwitchOffs(1, row.sunsets),
      size: "medium",
    });
    array.push({
      id: "switch_off_2g_status",
      label: "2G Switch Off Status",
      sortable: true,
      active: sortBy === "switch_off_2g_status",
      desc: sortBy === "switch_off_2g_status" ? desc : false,
      value: (row: any) => formatSwitchOffs(2, row.sunsets),
      size: "medium",
    });
    array.push({
      id: "switch_off_3g",
      label: "3G Switch Off",
      sortable: true,
      active: sortBy === "switch_off_3g",
      desc: sortBy === "switch_off_3g" ? desc : false,
      value: (row: any) => formatSwitchOffs(3, row.sunsets),
      size: "medium",
    });
    array.push({
      id: "switch_off_3g_status",
      label: "3G Switch Off Status",
      sortable: true,
      active: sortBy === "switch_off_3g_status",
      desc: sortBy === "switch_off_3g_status" ? desc : false,
      value: (row: any) => formatSwitchOffs(4, row.sunsets),
      size: "medium",
    });

    if (values.downloadSpeedControl !== "all") {
      array.push({
        id: "download_speed",
        label: "Download Speed",
        sortable: false,
        value: (row: any) => {
          return `${row.download_speed_title ? row.download_speed_title : ""}`;
        },
        size: "medium",
      });
    }
  }

  if (values.technologies.includes(5) && (values as any)?.coverage_5g) {
    array.push({
      id: "coverage_5g",
      label: "5G Coverage",
      sortable: false,
      value: (row: any) => `${row.coverage_5g}`,
      size: "large",
    });
  }

  if (isPrivate) {
    // new PMN
    array.push({
      id: "vertical",
      label: "Vertical",
      sortable: true,
      active: sortBy === "vertical",
      desc: sortBy === "vertical" ? desc : false,
      value: (row: any) => `${row.vertical ?? ""}`,
      size: "large",
    });

    array.push({
      id: "subvertical",
      label: "Subvertical",
      sortable: true,
      active: sortBy === "subvertical",
      desc: sortBy === "subvertical" ? desc : false,
      value: (row: any) => `${row.subvertical ?? ""}`,
      size: "large",
    });

    array.push({
      id: "network_type",
      label: "Technology ",
      sortable: true,
      active: sortBy === "network_type",
      desc: sortBy === "network_type" ? desc : false,
      value: (row: any) => `${row.technologies.map((option: OptionType) => option.title || "").join(", ")}`,
      size: "large",
    });

    array.push({
      id: "announced_year",
      label: "Year announced",
      sortable: true,
      active: sortBy === "announced_year",
      desc: sortBy === "announced_year" ? desc : false,
      value: (row: any) => `${row.announced_year === 0 ? "-" : (row.announced_year ?? "-")}`,
      size: "medium",
    });

    array.push({
      id: "spectrum_bands",
      label: "Spectrum bands",
      sortable: true,
      active: sortBy === "spectrum_bands",
      desc: sortBy === "spectrum_bands" ? desc : false,
      value: (row: any) => `${row.spectrum_bands ? spreadTitles(row.spectrum_bands) : ""}`,
      size: "large",
    });

    array.push({
      id: "url",
      label: "URL",
      sortable: false,
      value: (row: any) => row.url ?? "",
      externalUrl: (row: any) => row.url || undefined,
      size: "large",
    });
  }

  return array;
};

export const devicesCells = (sortBy: string, desc: boolean, values: DevicesValuesType): TableCell[] => {
  const array = [
    {
      id: "device_vendor_title",
      label: "Vendor",
      sortable: true,
      active: sortBy === "device_vendor_title",
      desc: sortBy === "device_vendor_title" ? desc : false,
      size: "medium",
      value: (row: any) => `${row.device_vendor_title}`,
    },

    {
      id: "model",
      label: "Model",
      sortable: true,
      active: sortBy === "model",
      desc: sortBy === "model" ? desc : false,
      value: (row: any) => `${row.model}`,
      size: "medium",
    },
    {
      id: "chipset",
      label: "Chipset",
      sortable: false,
      value: (row: any) => `${row.chipset_title}`,
      link: {
        urlBase: `${routes.chipsets.path}/chipset/`,
        urlParam: "chipset_id",
      },
      size: "medium",
    },
    {
      id: "form_factor",
      label: "Form Factor",
      sortable: true,
      active: sortBy === "form_factor",
      desc: sortBy === "form_factor" ? desc : false,
      value: (row: any) => `${row.form_factor_title}`,
      size: "small",
    },
    {
      id: "frequencies",
      label: "Frequencies",
      sortable: false,
      value: (row: any) => row.frequency_bands.map((option: OptionType) => option.title || "").join(", "),
      size: "medium",
    },
    {
      id: "fallbacks",
      label: "Fallbacks",
      sortable: false,
      value: (row: any) => row.fallbacks.map((option: OptionType) => option.title || "").join(", "),
      size: "medium",
    },
    {
      id: "support",
      label: "Support",
      sortable: false,
      value: (row: any) => row.supports.map((option: OptionType) => option.title || "").join(", "),
      size: "medium",
    },
    {
      id: "technology",
      label: "Technology",
      sortable: true,
      active: sortBy === "technology",
      desc: sortBy === "technology" ? desc : false,
      value: (row: any) => `${row.technology_title}`,
      size: "small",
    },
    {
      id: "commercial",
      label: "Commercial",
      sortable: false,
      value: (row: any) => commercialOptions[row.commercial || 0].title,
      size: "small",
    },
  ];

  if (values.technologies.includes(5)) {
    array.push({
      id: "availability",
      label: "Availability",
      sortable: false,
      value: (row: any) =>
        `${row.availability_year ? row.availability_year : ""}${row.availability_year && row.availability_quarter ? " - " : ""}${
          row.availability_quarter ? `Q${row.availability_quarter}` : ""
        }`,
      size: "small",
    });
  }

  if (values.redcaps.length) {
    array.splice(array.length - (values.technologies.includes(5) ? 2 : 1), 0, {
      id: "redcap",
      label: "RedCap",
      sortable: false,
      value: (row: any) => row.redcaps.map((option: OptionType) => option.title || "").join(", "),
      size: "small",
    });
  }

  return array;
};

export const chipsetsCells = (sortBy: string, desc: boolean): TableCell[] => [
  {
    id: "model",
    label: "Model",
    sortable: true,
    active: sortBy === "model",
    desc: sortBy === "model" ? desc : false,
    value: (row: any) => `${row.model}`,
    link: {
      urlBase: `${routes.chipsets.path}/chipset/`,
      urlParam: "id",
    },
    size: "medium",
  },
  {
    id: "chipset_type",
    label: "Chipset Type",
    sortable: false,
    value: (row: any) => `${row.chipset_type}`,
    size: "medium",
  },
  {
    id: "chipset_vendor",
    label: "Chipset Vendor",
    sortable: false,
    value: (row: any) => `${row.chipset_vendor}`,
    size: "medium",
  },
  {
    id: "ue_cat",
    label: "UE Category",
    sortable: false,
    value: (row: any) => `${row.ue_cat ? row.ue_cat : ""}`,
    size: "small",
  },
  {
    id: "speeds",
    label: "Speeds",
    sortable: false,
    value: (row: any) => row.speeds.map((option: OptionType) => option.title || "").join(", "),
    size: "medium",
  },
  {
    id: "supports",
    label: "Supports",
    sortable: false,
    value: (row: any) => row.supports.map((option: OptionType) => option.title || "").join(", "),
    size: "medium",
  },
  {
    id: "technologies",
    label: "Technologies",
    sortable: false,
    value: (row: any) => row.technologies.map((option: OptionType) => option.title || "").join(", "),
    size: "small",
  },
  {
    id: "commercial",
    label: "Commercial",
    sortable: false,
    value: (row: any) => commercialOptions[row.commercial || 0].title,
    size: "small",
  },
];

export const operatorsCells = (sortBy: string, desc: boolean): TableCell[] => [
  {
    id: "operator",
    label: "Operator",
    sortable: true,
    active: sortBy === "title",
    desc,
    value: (row: any) => `${row.title}`,
    link: {
      urlBase: `${routes.operators.path}/operator/`,
      urlParam: "id",
    },
    size: "medium",
  },
  {
    id: "country",
    active: sortBy === "country_id",
    label: "Country",
    sortable: false,
    value: (row: any) => `${row.country_id}`,
    size: "medium",
  },
  {
    id: "download_speed",
    label: "Opensignal Download Speed average/5G (Mbps)",
    sortable: true,
    active: sortBy === "download_speed",
    desc: sortBy === "download_speed" ? desc : false,
    value: (row: any) =>
      row.opensignals.length > 0 ? `${row.opensignals[0].download_speed} / ${row.opensignals[0]["download_speed_5g"]}` : "",
    size: "medium",
  },
  {
    id: "upload_speed",
    label: "Opensignal Upload Speed average/5G (Mbps)",
    sortable: true,
    active: sortBy === "upload_speed",
    desc: sortBy === "upload_speed" ? desc : false,
    value: (row: any) =>
      row.opensignals.length > 0 ? `${row.opensignals[0].upload_speed} / ${row.opensignals[0]["upload_speed_5g"]}` : "",
    size: "medium",
  },
  {
    id: "video_score",
    label: "Opensignal Video Experience score average/5G",
    sortable: true,
    active: sortBy === "video_score",
    desc: sortBy === "video_score" ? desc : false,
    value: (row: any) => (row.opensignals.length > 0 ? `${row.opensignals[0].video_score} / ${row.opensignals[0]["video_score_5g"]}` : ""),
    size: "medium",
  },
  {
    id: "games_score",
    label: "Opensignal Games Experience score average/5G",
    sortable: true,
    active: sortBy === "games_score",
    desc: sortBy === "games_score" ? desc : false,
    value: (row: any) => (row.opensignals.length > 0 ? `${row.opensignals[0].games_score} / ${row.opensignals[0]["games_score_5g"]}` : ""),
    size: "medium",
  },
  {
    id: "availability",
    label: "Opensignal Availability average/5G (%)",
    sortable: true,
    active: sortBy === "availability",
    desc: sortBy === "availability" ? desc : false,
    value: (row: any) =>
      row.opensignals.length > 0 ? `${row.opensignals[0].availability} / ${row.opensignals[0]["availability_5g"]}` : "",
    size: "medium",
  },
  /*   {
    id: "switch_off_2g3g",
    label: "2G/3G Switch Off",
    sortable: true,
    active: sortBy === "switch_off_2g3g",
    desc: sortBy === "switch_off_2g3g" ? desc : false,
    value: (row: any) => JSON.stringify(row.opensignals),
    size: "large",
  }, */
];
